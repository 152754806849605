import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GalleryView } from './GalleryView';
import { projects } from '../projects/data';
import useEmblaCarousel from 'embla-carousel-react';
import { NextButton, PrevButton, usePrevNextButtons } from './CarouselButtons';
import { DotButton, useDotButton } from './CarouselDotButton';
import gsap from 'gsap/all';
import IconClose from '../images/icon-close.svg';
import useLayoutEffect from '../hooks/useIsomorphicLayoutEffect';

type CarouselProps = {
  slideIdx: number;
  setModalActive: (active: boolean) => void;
};

export const Carousel = ({ slideIdx, setModalActive }: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ startIndex: slideIdx });

  const { selectedIndex, scrollSnaps } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  const handleKeydown = (event: any) => {
    if (event.key === 'ArrowRight') {
      event.preventDefault();
      emblaApi?.scrollNext();
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      emblaApi?.scrollPrev();
    }
  };

  useEffect(() => {
    if (!emblaApi) return;
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [emblaApi]);

  useLayoutEffect(() => {
    if (scrollSnaps.length > 0) {
      let ctx = gsap.context(() => {
        gsap.fromTo(
          '.modal-close-btn',
          {
            opacity: 0,
            y: -20,
          },
          {
            delay: 0.5,
            opacity: 1,
            y: 0,
            duration: 0.8,
          }
        );

        gsap.fromTo(
          '.embla__buttons',
          {
            opacity: 0,
            y: -20,
          },
          {
            delay: 0.5,
            opacity: 1,
            y: 0,
            duration: 1,
          }
        );

        gsap.fromTo(
          '.embla__dot',
          {
            opacity: 0,
            y: 60,
            zIndex: -1,
          },
          {
            delay: 0.5,
            opacity: 1,
            y: 0,
            stagger: 0.06,
          }
        );
      }, '.carousel');
      return () => ctx.revert();
    }
  }, [scrollSnaps]);

  return (
    <div className='carousel'>
      <button
        className='modal-close-btn'
        aria-label='Schließen'
        onClick={() => setModalActive(false)}
      >
        <img width={24} height={24} src={IconClose} alt='Schließen' />
      </button>

      <div
        style={{
          position: 'relative',
          width: '100vw',
          height: '100vh',
          height: '100dvh',
          overflow: 'hidden',
        }}
      >
        <div className='embla' ref={emblaRef}>
          <div className='embla__container'>
            {projects.map((project, index) => (
              <div key={project.title} className='embla__slide'>
                <GalleryView data={project} active={selectedIndex === index} />
              </div>
            ))}
          </div>
        </div>

        <div className='embla__buttons'>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className='embla__dots'>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
