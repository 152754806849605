import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap/all';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import splt from 'spltjs';
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import Arrow from '../../images/arrow-down.svg';
import Flux from '../../images/flux.svg';

export const Hero = () => {
  useLayoutEffect(() => {
    splt({});
    let ctx = gsap.context(() => {
      gsap.fromTo(
        '.hero-img',
        {
          opacity: 0,
        },
        { opacity: 0.3, duration: 0.8 }
      );
      gsap.fromTo(
        '.fade-in',
        {
          opacity: 0,
          y: -20,
        },
        { opacity: 1, y: 0, stagger: 0.4, duration: 0.4, delay: 0.3 }
      );

      gsap.fromTo(
        '.flux',
        {
          opacity: 0,
        },
        { opacity: 0.3, duration: 0.8 }
      );

      gsap.fromTo(
        '.splt span',
        {
          opacity: 0,
          transform: 'translateY(-50px)',
          ease: 'cubicBezier(.71,-0.77,.43,1.67)',
        },
        { opacity: 1, duration: 1, stagger: 0.06, transform: 'translateY(0px)' }
      );
    }, '#hero');

    return () => ctx.revert();
  }, []);

  return (
    <section id='hero' className='has-grain'>
      <img src={Flux} alt='' className='flux' />

      <div className='hero-content'>
        <Parallax speed={10}>
          <h1 className='headline fade-in'>
            <p className='splt'>imagine</p>
            <p className='splt'>create</p>
            <p className='splt'>remember</p>
          </h1>
        </Parallax>

        <Parallax className='illu' speed={-5}>
          <StaticImage
            className='fade-in'
            placeholder='none'
            src={'../../images/hero-image.webp'}
            alt='Abstrakte Scribbles von einer Hand die auf einem Laptop tippt'
            loading='eager'
            sizes='40vw'
          />
        </Parallax>
      </div>

      {/* <Link className='al arrow-down fade-in' to='/#about' data-scroll='#about'>
        <img src={Arrow} alt='Scrolle zur nächsten Sektion' />
      </Link> */}

      <StaticImage
        className='hero-img'
        placeholder='none'
        src={'../../images/hero.webp'}
        alt=''
        loading='eager'
        sizes='100vw'
      />
    </section>
  );
};
