import { StaticImage } from 'gatsby-plugin-image';
import gsap from 'gsap/all';
import React, { useState } from 'react';
import Modal from 'react-modal';
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import Scribble from '../../images/scribble-2.svg';
import { projects } from '../../projects/data';
import { Carousel } from '../Carousel';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(3px) saturate(0.6)',
    zIndex: 1000,
  },
};

export const Gallery = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedIndex, setIndex] = useState(0);

  const handleModal = (index: number) => {
    setModalActive(!modalActive);
    setIndex(index);
  };

  // min and max included
  const getRandomFrame = (index: number, min: number, max: number) => {
    let number = index + 1;
    if (number > 5) number = Math.floor(Math.random() * (max - min + 1) + min);

    switch (number) {
      case 1:
        return <StaticImage className='frame-border' src='../../images/frameBorder1.webp' alt='' />;
      case 2:
        return <StaticImage className='frame-border' src='../../images/frameBorder2.webp' alt='' />;
      case 3:
        return <StaticImage className='frame-border' src='../../images/frameBorder3.webp' alt='' />;
      case 4:
        return <StaticImage className='frame-border' src='../../images/frameBorder4.webp' alt='' />;
      default:
        return <StaticImage className='frame-border' src='../../images/frameBorder5.webp' alt='' />;
    }
  };

  useLayoutEffect(() => {
    Modal.setAppElement('body');
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({
        scrollTrigger: { trigger: '.film-wrapper', start: '50% bottom' },
      });

      timeline
        .fromTo(
          '.film',
          {
            opacity: 0,
            x: 100,
            duration: 0.4,
          },
          {
            opacity: 1,
            x: 0,
          }
        )
        .fromTo(
          '.thumbnail',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            stagger: 0.1,
            duration: 1,
            ease: 'ease',
          }
        );

      gsap.to('.film', {
        scrollTrigger: {
          trigger: '.film-wrapper',
          start: 'top bottom',
          end: 'center 70%',
          scrub: 1,
        },
        x: 0,
        opacity: 1,
        ease: 'ease-out',
      });
    }, '#gallery');

    return () => ctx.revert();
  }, []);

  return (
    <section id='gallery'>
      <div className='wrapper'>
        <div className='heading-wrapper'>
          <h2 className='heading'>Galerie</h2>
          <img className='scribble' src={Scribble} alt='' />
        </div>
      </div>

      <div className='film-wrapper'>
        <div className='track' />
        <div className='film' id='film'>
          <div className={'dummy-frame'} tabIndex={-1}>
            <StaticImage src='../../images/frame.webp' alt='' />
          </div>
          {projects.map((project: any, index: number) => {
            return (
              <div
                key={index.toString()}
                onClick={() => handleModal(index)}
                className={['frame', modalActive && selectedIndex === index ? 'active' : ''].join(
                  ' '
                )}
                tabIndex={0}
              >
                <StaticImage src='../../images/frame.webp' alt='' />
                {getRandomFrame(index, 1, 5)}
                {project.source}
              </div>
            );
          })}
          <div className={'dummy-frame'} tabIndex={-1}>
            <StaticImage src='../../images/frame.webp' alt='' />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalActive}
        onRequestClose={() => setModalActive(false)}
        closeTimeoutMS={300}
        style={customStyles}
      >
        <Carousel slideIdx={selectedIndex} setModalActive={setModalActive} />
      </Modal>
    </section>
  );
};
