import React from 'react';
import ScrollSpy from 'react-ui-scrollspy';
import Footer from '../components/Footer';
import { useActiveScrollspy } from '../components/ScrollSpyContext';
import { Seo } from '../components/Seo';
import { About, Contact, Gallery, Hero } from '../components/sections';
import { Cards } from '../components/sections/Cards';
import '../styles/styles.scss';
import { Noise } from '../components/Noise';

const IndexPage = () => {
  const { setActiveElement } = useActiveScrollspy();

  return (
    <>
      <Noise />

      <main>
        <ScrollSpy
          offsetTop={400}
          offsetBottom={400}
          activeClass='active'
          onUpdateCallback={(x) => {
            const el = document.querySelector('[data-to-scrollspy-id=' + x);
            el && setActiveElement(el);
          }}
          updateHistoryStack={false}
        >
          <Hero />
          <About />
          <Cards />
          <Gallery />
          <Contact />
        </ScrollSpy>
      </main>

      <Footer animated />
    </>
  );
};
export default IndexPage;

export const Head = () => <Seo />;
